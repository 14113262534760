<template>
     <div v-if="isLoggedIn == true" class="container">
        <p class="error" v-if="errors.length">
               <ul>
                  <li v-for:='error in errors'>{{ error }}</li>
               </ul>
        </p>
        <div v-if="loader">
          <ProgressSpinner id="loader" />
      </div>
      

        <div v-if="!haveVariants" class="vendor_section">
            <div class="vendor_header">
                <h3>Search</h3>
                <span class="search_bar p-input-icon-right">
                    <i class="pi pi-search" />
                    <InputText v-model="searchText" placeholder="Search by vendor name"></InputText>
                </span>
            </div>
            <div class="vendorlList">
                <div v-bind:class="(selectedVendors.length >= 5)?'filled_checked':'checkbox_area'">
                    <div class="p-field-checkbox" v-for="(vendor, index) in filteredVendors" :key="index">
                        <Checkbox v-model="checkedVendors" :value="vendor.vendor_name" :disabled="isCheckboxDisabled(vendor.vendor_name)"></Checkbox>
                        <label :for="vendor.vendor_name">{{ vendor.vendor_name }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="export_content">
            <div v-if="checkedVendors.length" class="selected_vendors">
                <div class="selection_head">
                    <h3>Select vendor to export</h3>
                    <Chip :label="option" v-for="option of checkedVendors" :key="option" removable :onRemove="removeSelectVendors" />
                        
                </div>
                 <div class="generate_btn">
                        <Button icon="pi pi-file" label="Export Now" @click="getVariantByvendors"></Button>
                    </div>
            </div>
            <div v-else>
                <span>No vendor selected</span>
            </div>    
        </div>
        <div v-if="haveVariants" class="success_data">
            <Button icon="pi pi-external-link" label="Export to CSV" @click="exportToCsv"></Button>
        </div>
        <div v-bind:class="(loader)?'loading':'loaded'"></div>
        <div class="imp_note"><b>Note:</b>  Please note that you can only select a maximum of five vendors for export at a time.</div>
    </div>
    <div v-else class="error">
      Please login to check the page <a href="/">Login</a>
    </div>
</template>
  
<script>
import { mapActions, mapGetters } from "vuex";
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import ProgressSpinner from 'primevue/progressspinner';
import Button from 'primevue/button';
import Chip from 'primevue/chip';
import Papa from 'papaparse';

export default {
    name: 'CheckboxList',
    components: {
        Checkbox,
        Button,
        InputText,
        Chip,
        ProgressSpinner
    },
    data() {
        return {
            vendors: [],
            checkedVendors: [],
            selectedVendors: [],
            searchText: '',
            loader:true,
            errors:[],
            haveVariants:false,
            variants:[]
        };
    },
    watch: {
        async checkedVendors(newVal) {
            this.selectedVendors = newVal;
        },
    },
    methods: {
        ...mapActions(["getBrands"]),
        ...mapActions(["getVariantsByVendorList"]),
        ...mapGetters(["isAuthenticated"]),
        async getVariantByvendors (){
             this.loader = true;
             const data = {"vendors":this.selectedVendors,"token":this.userToken};
             const values = await this.getVariantsByVendorList(data);
             if(values.data.status != 200){this.loader = false;this.clearSelection();alert(values.data.message);return;}
             this.variants = values.data.variants;
             this.haveVariants = true;
             await this.exportToCsv();
             this.clearSelection();
             this.loader = false;
        },
        async exportToCsv() {
           const data = this.variants.map((item) => {return {
            name:item.displayName,
            sku:item.sku,
            variantID:item.legacyResourceId,
            productID:item.product.legacyResourceId,
            metafieldID:item.metafield?.legacyResourceId?item.metafield.legacyResourceId:null,
            metafieldValue:item.metafield?.value?item.metafield.value:null
          } });
           const header = ['name', 'productID', 'sku',"variantID","metafieldID","metafieldValue"];
           const csvData = Papa.unparse({data:data,fields: header});
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download','download.csv');
            document.body.appendChild(link);
            link.click();
        },
        async clearSelection(){
            this.haveVariants = false;
            this.selectedVendors = [];  
            this.checkedVendors= [];
            this.variants = [];
        },
        isCheckboxDisabled(id) {
            return this.selectedVendors.length >= 5 && !this.selectedVendors.includes(id);
        },
        async removeSelectVendors(event){
            const removeVendor = event.target.parentElement?.getAttribute('aria-label');
            console.log("removeVendor",removeVendor);
            console.log("this.checkedVendors",this.checkedVendors);
            // var index = this.selectedVendors.indexOf(removeVendor);
            // if(index!=-1){this.selectedVendors.splice(index, 1);}
            var index1 = this.checkedVendors.indexOf(removeVendor);
            if(index1!=-1){this.checkedVendors.splice(index1, 1);}
            console.log("this.checkedVendors after",this.checkedVendors);
            console.log("this.selectedVendors after",this.selectedVendors);
            // console.log("this.selectedVendors",this.selectedVendors)
            // this.selectedVendors = this.selectedVendors.indexOf(removeVendor) !== -1 && this.selectedVendors.splice(this.selectedVendors.indexOf(removeVendor), 1);
            // this.checkedVendors = this.checkedVendors.indexOf(removeVendor) !== -1 && this.checkedVendors.splice(this.checkedVendors.indexOf(removeVendor), 1);

            // this.selectedVendors = this.selectedVendors.filter(function(item) {
            //     return item !== removeVendor
            // });
            // this.checkedVendors = this.checkedVendors.filter(function(item) {
            //     return item !== removeVendor
            // })
        }
    },
    mounted: async function () {
        if (this.isLoggedIn) {
            this.vendors = await this.getBrands(this.userToken);
            this.loader = false;
        }
    },
    computed: {
        filteredVendors() {
            return this.vendors.filter((vendor) =>
                vendor.vendor_name.toLowerCase().includes(this.searchText.toLowerCase())
            );
        },
        isLoggedIn: function () {
            return this.$store.getters.isAuthenticated
        },
        userToken: function () {
            return this.$store.getters.getUserToken
        },
    },
};
</script>
  
<style scoped>
.selected-fruits {
    display: flex;
    flex-wrap: wrap;
}

.selected-fruit {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 5px;
    background-color: #f1f1f1;
}
.vendorlList .p-field-checkbox {
    margin: 15px 0px;
}

.vendor_section .p-field-checkbox label {
    color: #333;
    margin-left: 10px;
    font-size: 15px;
}

.p-field-checkbox {
    display: block;
    text-align: left;
}
.filled_checked .p-checkbox.p-component.p-checkbox-disabled + label {
    opacity: 0.5;
}

.generate_btn {
    display: block;
    margin-top: 30px;
}
</style>
  